import { useEffect, useState } from "react";
import "./style.scss";
import { useAuth } from "../../services/api";
import axios from "axios";
import { Link } from "react-router-dom";

export default function TalentsList(category) {
  // const [modelGender] = useState(gender.gender);
  const [modelCategory] = useState(category.category);

  const key = useAuth().key;
  useEffect(() => {
    console.log(modelCategory);

    if (key) {
      axios
        .get(
          "https://integration.managerfashion.net/api/talents?paginate=200",
          {
            headers: {
              Authorization: `bearer ` + key,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          setTalentsData(response.data.data);
        });
    }
  }, [key]);

  // const [loading, setLoading] = useState(false);

  const [talentsData, setTalentsData] = useState([]);

  const modelsFilter = talentsData.filter((model) => {
    let categoryFound = false;

    model.categories.forEach((category) => {
      console.log(modelCategory);
      if (category.id == modelCategory) {
        categoryFound = true;
      }
    });

    return categoryFound;
  });

  console.log(modelsFilter);

  return (
    <div className="App">
      <div className="container">
        <div className="row models">
          {modelsFilter?.map((model, index) => (
            <div
              className="col-6 col-md-3 col-lg-2 p-2 p-0 position-relative modelbox"
              key={index}
            >
              <picture>
                <Link to={"/model/" + model.id}>
                  <img
                    src={model.cover}
                    alt={model.name}
                    className="img-fluid"
                  />
                </Link>
              </picture>
              <h3>{model.name}</h3>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
