import logo from "../../assets/img/logo.png";
import logomanagement from "../../assets/img/logo-managment.png";

const Home = () => {
  return (
    <>
        <div className="intro" style={{ background: "#000", height: "100vh" }}>
          <div className="container-fluid">

            <div className="row min-vh-100 align-items-center d-flex">
              <div
                className="col-6 position-relative min-vh-100 bg-white d-flex align-items-center justify-content-center "
              >
                <div className="box p-1 p-md-5 w-100 d-flex justify-content-center p-7">
                    <a href="https://ravagemodels.com.br/" rel="noreferrer" target="_blank" className="stretched-link"><img src={logo} width="100%"  /></a>
                </div>  
                
              </div>
              <div
                className="col-6 position-relative bg-white min-vh-100 p-7 d-flex align-items-center justify-content-center"
                
              >

                <div className="box p-1 p-md-5 w-100 d-flex justify-content-center p-7">
                <a href="/mainboard" className="stretched-link"><img src={logomanagement} width="100%" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default Home;
