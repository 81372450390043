import { Container } from "react-bootstrap";
import { Footer } from "../../components/Footer";
import Header from "../../components/Header";
import ModelsList from "../../components/ModelsList/ModelsList";

const Mainboard = () => {
  return (
    <>
      <div className="mainboard">
        <Container>
          <ModelsList category="1879" />
        </Container>
      </div>
    </>
  );
};

export default Mainboard;
