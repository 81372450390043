import style from "./style.scss";
import { Container, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-grid-carousel";

import { useAuth } from "../../services/api";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Header from "../Header";
import { Footer } from "../Footer";

export default function Model() {
  const key = useAuth().key;

  let { id } = useParams();

  const [modelData, setModelData] = useState([]);
  const [gallery, setGallery] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (key) {
      axios
        .get(
          "https://integration.managerfashion.net/api/talent/profile?id=" + id,
          {
            headers: {
              Authorization: `bearer ` + key,
            },
          }
        )
        .then((response) => {
          setModelData(response.data);
          console.log(response.data);
        });
    }
  }, [key]);

  return (
    <>
      <div className="model">
        {!gallery && (
          <Container>
            <div className="row">
              <div className="col-12 col-md-6 text-center text-md-end picture">
                <figure>
                  <img
                    src={modelData.cover}
                    alt={modelData.name}
                    onClick={() => setGallery(true)}
                  />
                </figure>
              </div>

              <div className="col-12 col-md-6 ps-5">
                <h1 className="mb-2">{modelData.name}</h1>

                {modelData.instagram && (
                  <div className="insta mb-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                    </svg>{" "}
                    <a
                      className="text-decoration-none text-black"
                      href={"https://www.instagram.com/" + modelData.instagram}
                      target="_blank"
                    >
                      View Instagram Profile
                    </a>
                  </div>
                )}

                <ul className="list-unstyled">
                  <li>
                    <strong>Height:</strong> {modelData.height}
                  </li>
                  <li>
                    <strong>Bust:</strong> {modelData.bust}
                  </li>
                  <li>
                    <strong>Waist:</strong> {modelData.waist}
                  </li>
                  <li>
                    <strong>Hips:</strong> {modelData.hips}
                  </li>
                  <li>
                    <strong>Shoes:</strong> {modelData.shoes}
                  </li>
                  <li>
                    <strong>Hair:</strong> {modelData.hair}
                  </li>
                  <li>
                    <strong>Eyes:</strong> {modelData.eyes}
                  </li>
                </ul>
              </div>
            </div>
          </Container>
        )}

        {gallery && (
          <div className="modal-gallery">
            <Carousel cols={2} rows={1} gap={10} loop>
              {modelData.book &&
                Object.values(modelData.book).map((image) => (
                  <Carousel.Item>
                    <img width="100%" src={image.file} />
                  </Carousel.Item>
                ))}
            </Carousel>
            <h4 className="text-center p-4">{modelData.name}</h4>
            <div className="info-gallery">
              <ul className="w-100 list-unstyled d-flex flex-column flex-md-row justify-content-center align-items-center">
                <li>
                  <strong>Height:</strong> {modelData.height}
                </li>
                <li>
                  <strong>Bust:</strong> {modelData.bust}
                </li>
                <li>
                  <strong>Waist:</strong> {modelData.waist}
                </li>
                <li>
                  <strong>Hips:</strong> {modelData.hips}
                </li>
                <li>
                  <strong>Shoes:</strong> {modelData.shoes}
                </li>
                <li>
                  <strong>Hair:</strong> {modelData.hair}
                </li>
                <li>
                  <strong>Eyes:</strong> {modelData.eyes}
                </li>
              </ul>
            </div>

            <button
              className="bg-black text-white mx-auto d-block rounded"
              onClick={() => setGallery(false)}
            >
              &larr; BACK
            </button>
          </div>
        )}
      </div>
    </>
  );
}
