import { NavLink } from "react-router-dom";

export function Footer() {
  return (
    <>
      <footer>
        <p className="text-center p-3">
          Ravage Models © 2023 |{" "}
          <NavLink className="text-black" to="/privacy">
            Privacy Policy
          </NavLink>
        </p>
      </footer>
    </>
  );
}
