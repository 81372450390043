import logoBlack from "../../../assets/img/logo.png";

const Logo = () => {
  return (
    <>
      <a href="/">
        <img src={logoBlack} alt="Ravagem Gmt" className="img-fluid" />
      </a>
    </>
  );
};

export default Logo;
