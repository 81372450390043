import { Container, Form } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import "./style.scss";
import { useState } from "react";
const Contact = () => {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      console.log("não validou");
    } else {
      setValidated(true);
      const templateParams = {
        name: document.getElementById("name").value,
        user_email: document.getElementById("email").value,
        phone: document.getElementById("phone").value,
        subject: document.getElementById("subject").value,
        message: document.getElementById("message").value,
      };

      emailjs
        .send(
          "service_gfy9jym",
          "template_atxr828",
          templateParams,
          "Wrwgw9xXtdQsEVdE-"
        )
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
          },
          (err) => {
            console.log("FAILED...", err);
          }
        );
    }
  };

  return (
    <>
      <div className="contact">
        <Container>
          <div className="row">
            <div className="col-12 col-md-5">
              <div className="contact-container">
                <div className="box p-md-4 pb-3">
                  <h3>Administration</h3>
                  <ul className="list-unstyled m-0">
                    <li>Phone: +55 61 98355-5036</li>
                    <li>Location: Brasília/DF, Brasil</li>
                    <li>info@ravagemgmt.com</li>
                    <li>ravagemodelmanagement@gmail.com</li>
                    <li>
                      Address: Scs Quadra 1, Bloco B (Edificio Maristela),
                      <br />
                      6th Floor, office 614 asa sul, Brasilia, Distrito Federal,
                      Brasil 70.308-900
                    </li>
                  </ul>
                </div>

                <div className="box p-md-4 pb-3">
                  <h3>Brazil</h3>
                  <ul className="list-unstyled m-0">
                    <li>Phone: +55 61 98355-5036</li>
                    <li>SP - bookings@ravagemgmt.com</li>
                    <li>GYN - booker1@ravagemgmt.com</li>
                    <li>BSB booker2@ravagemgmt.com</li>
                  </ul>
                </div>

                <div className="box p-md-4 pb-3">
                  <h3>International</h3>
                  <ul className="list-unstyled m-0">
                    <li>Phone: +55 61 98272-3496</li>
                    <li>wesleyjames.scout@gmail.com</li>
                    <li>natalia.ravage@gmail.com</li>
                    <li>mike.ravagemgmt@gmail.com</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-7">
              <h4>Contact Form</h4>
              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                id="contact-form"
              >
                <input type="hidden" name="contact_number" />
                <div className="form-group mb-2">
                  <label for="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="name"
                    required
                  />
                </div>
                <div className="form-group mb-2">
                  <label for="email">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="user_email"
                    id="email"
                    required
                  />
                </div>
                <div className="form-group mb-2">
                  <label>Phone</label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group mb-2">
                  <label>Subject</label>
                  <input
                    type="text"
                    name="subject"
                    id="subject"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group mb-2">
                  <label>Message</label>
                  <textarea
                    name="message"
                    id="message"
                    rows={10}
                    className="form-control"
                  ></textarea>
                </div>
                <input
                  className="bg-black text-white p-1 px-5"
                  type="submit"
                  value="Send"
                />
                {validated && (
                  <div className="mt-2 alert alert-success">
                    Form sent successfully, wait for our contact
                  </div>
                )}
              </Form>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Contact;
