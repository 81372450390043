import "bootstrap/dist/css/bootstrap.min.css";
import * as React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Footer } from "./components/Footer";
import Header from "./components/Header";
import Model from "./components/Model";
import Contact from "./pages/contact";
import Home from "./pages/home";
import Mainboard from "./pages/mainboard";
import Development from "./pages/development";
import { ProvideAuth } from "./services/api";
import { useEffect } from "react";
import Privacy from "./pages/policy-privacy";

export default function App() {
  let location = useLocation();

  useEffect(() => {
    // Update the document title using the browser API
  }, [location]);

  return (
    <>
      {window.location.pathname !== "/" && <Header />}
      <ProvideAuth>
        <Routes>
          <Route className="home" exact path="/" element={<Home />}></Route>
          <Route
            path="/development"
            activeClassName="is-active"
            element={<Development />}
          ></Route>
          <Route
            path="/mainboard"
            activeClassName="is-active"
            element={<Mainboard />}
          ></Route>
          <Route
            path="/contact"
            activeClassName="is-active"
            element={<Contact />}
          ></Route>
          <Route
            path="/privacy"
            activeClassName="is-active"
            element={<Privacy />}
          ></Route>
          <Route path="/model/:id" element={<Model />}></Route>
        </Routes>
      </ProvideAuth>
      {window.location.pathname !== "/" && <Footer />}
    </>
  );
}
