import { Container } from "react-bootstrap";

export default function Privacy() {
  return (
    <>
      <Container>
        <h1>Security</h1>
        <p>
          To protect your personal information, we take reasonable precautions
          and follow industry best practices to make sure it is not
          inappropriately lost, misused, accessed, disclosed, altered or
          destroyed.
        </p>

        <p>
          Our website is encrypted using secure socket layer technology (SSL)
          and stored with a AES-256 encryption and all users who have access to
          the backend systems sit behind firewalls and can only access backend
          data via SSH. Although no method of transmission over the Internet or
          electronic storage is 100% secure, we follow all PCI-DSS requirements
          and implement additional generally accepted industry standards.
        </p>

        <p>
          We use a combination of software and hardware to achieve this level of
          security. The systems are monitored and maintained daily and all
          measures are taken to mitigate any data loss or breach.
        </p>

        <h2>Changes To This Privacy Policy</h2>

        <p>
          We reserve the right to modify this Privacy Policy at any time, so
          please review it frequently. Changes and clarifications will take
          effect immediately upon their posting on the website. If we make
          material changes to this policy, we will notify you here that it has
          been updated, so that you are aware of what information we collect,
          how we use it, and under what circumstances, if any, we use and/or
          disclose it.
        </p>
      </Container>
    </>
  );
}
