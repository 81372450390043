import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";

const authContext = createContext();

export function ProvideAuth({ children }) {
  const [key, setKey] = useState(null);
  const loginURL =
    "https://integration.managerfashion.net/api/signin?salt=b39addc402edf67fa34e9271d501d2a09af31e0f31fc5a28d4ffd7e41f5cd5236954b4fda12b01b869b6bdcd790171d7d24882777b5f90f9de2d3da704a9ce80&api_key=2380e34d9f4ovmoqz2e0k91z7iheeg";

  useEffect(() => {
    axios
      .post(loginURL, {
        headers: {
          Authorization: `${key}`,
        },
      })
      .then((response) => {
        setKey(response.data.access_token);
      });
  }, []);

  return (
    <authContext.Provider value={{ key }}>{children}</authContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(authContext);
};
