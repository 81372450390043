import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { Link, NavLink } from "react-router-dom";

export default function MainMenu() {
  return (
    <>
      <Navbar expand="lg" className="pt-4 pb-0">
        <Container className="text-center">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavLink to="/mainboard" activeClassName="active">
                Mainboard
              </NavLink>
              <NavLink to="/development" activeClassName="active">
                Development
              </NavLink>
              <NavLink to="/contact" activeClassName="active">
                Contact
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
