import React from "react";
import "./style.scss";

import Logo from "./Logo";
import MainMenu from "./MainMenu";

export default function Header() {
  return (
    <>
      <header className="header d-flex align-items-center justify-content-center flex-column p-4">
        <Logo />
        <MainMenu />
      </header>
    </>
  );
}
